<template>
  <div id="root">
    <div class="main">
      <div class="main-a">
        <img
          class="logo"
          src="../assets/碧麒助理.png"
        />
        <img
          class="desc-title"
          src="https://gw.alicdn.com/imgextra/i2/O1CN01wxBBsg1QWGGE0Ikj2_!!6000000001983-2-tps-279-126.png"
        />
        <div class="desc-content">
          碧麒助理是一款在线生成专业质感大片的AI相机，拥有潮流、时尚、有趣、好玩的风格模板，让你方便快捷地拥有百变照片，掌握引爆朋友圈的流量密码，成为社交圈的时尚先锋！
        </div>
      </div>
      <div class="main-b">
        <img
          class="big-logo"
          src="../assets/碧麒logopng.png"
        />
      </div>
    </div>
    <div class="download">
      <div class="dl-item">
        <div class="dl-button">
          <img
            src="https://gw.alicdn.com/imgextra/i4/O1CN01wytip11E7jfV2xgYr_!!6000000000305-2-tps-54-54.png"
            class="dl-icon"
          />
          <div class="dl-text">
            <a
              class="dl-link"
              href="https://apps.apple.com/cn/app/%E5%A6%99%E9%B8%AD%E7%9B%B8%E6%9C%BA-ai%E5%B8%AE%E4%BD%A0%E6%8B%8D%E5%86%99%E7%9C%9F/id1554441392"
            ></a>
            <div class="dl-store">ios App Store</div>
            <div class="dl-dl">下载</div>
          </div>
        </div>
        <div class="dl-qrcode">
          <img
            src="../assets/qrcode.png"
            class="qr-img"
          />
        </div>
      </div>
      <!-- 添加更多下载项 -->
    <div class="dl-item">
          <div class="dl-button">
            <img
              src="https://gw.alicdn.com/imgextra/i3/O1CN017eemJW1i1iwWXagZW_!!6000000004353-2-tps-54-54.png"
              class="dl-icon"
            />
            <div class="dl-text">
              <a
                class="dl-link"
                href="https://sj.qq.com/appdetail/com.talkclub.android"
              ></a>
              <div class="dl-store">Android商店</div>
              <div class="dl-dl">下载</div>
            </div>
          </div>
          <div class="dl-qrcode">
            <img
              src="../assets/qrcode.png"
              class="qr-img"
            />
          </div>
        </div>
      </div>
    <div class="tpl-banner">
      <img
        class="tpl-img"
        src="https://gw.alicdn.com/imgextra/i3/O1CN01b0ZUKK1LQhNScbhPe_!!6000000001294-2-tps-306-99.png"
        alt=""
      />
    </div>
    <!-- 添加更多模板和相关内容 -->
     <div class="tpl-title">模板—十年｜雪花</div>
      <div class="tpl-items">
        <img
          class="tpl-item"
          src="../assets/1.1.jpg"
        />
        <img
          class="tpl-item"
          src="../assets/2.2.jpg"
        />
        <img
          class="tpl-item"
          src="../assets/3.3.jpg"
        />
      </div>
      <div class="tpl-title">模板—红玫瑰｜甜度</div>
      <div class="tpl-items">
        <img
          class="tpl-item"
          src="../assets/4.4.jpg"
        />
        <img
          class="tpl-item"
          src="../assets/5.5.jpg"
        />
        <img
          class="tpl-item"
          src="../assets/6.6.jpg"
        />
      </div>
      <div class="tpl-title">模板—深情</div>
      <div class="tpl-items">
        <img
          class="tpl-item"
          src="../assets/7.7.jpg"
        />
        <img
          class="tpl-item"
          src="../assets/8.8.jpg"
        />
        <img
          class="tpl-item"
          src="../assets/9.9.jpg"
        />
      </div>
      <!-- <div class="tpl-title">模板—深咖</div>
      <div class="tpl-items">
        <img
          class="tpl-item"
          src="https://gw.alicdn.com/imgextra/i2/O1CN01JEsCxo21gSsEDfDnu_!!6000000007014-0-tps-576-768.jpg"
        />
        <img
          class="tpl-item"
          src="https://gw.alicdn.com/imgextra/i4/O1CN01oPLlwG1qDeBFeZOpG_!!6000000005462-0-tps-576-768.jpg"
        />
        <img
          class="tpl-item"
          src="https://gw.alicdn.com/imgextra/i4/O1CN01nWsvWG1H7gTK9KLFN_!!6000000000711-0-tps-576-768.jpg"
        />
      </div>
      <div class="tpl-title">模板—Z世代｜美高女孩</div>
      <div class="tpl-items">
        <img
          class="tpl-item"
          src="https://gw.alicdn.com/imgextra/i1/O1CN01dAl5it1x9FgnR0LMA_!!6000000006400-0-tps-576-768.jpg"
        />
        <img
          class="tpl-item"
          src="https://gw.alicdn.com/imgextra/i2/O1CN01MeP9h61CUW8hblNtq_!!6000000000084-0-tps-576-768.jpg"
        />
        <img
          class="tpl-item"
          src="https://gw.alicdn.com/imgextra/i1/O1CN0120lYyI1nEcAXeCW7i_!!6000000005058-0-tps-576-768.jpg"
        />
      </div>
      <div class="tpl-title">模板—Z世代｜美高男孩</div>
      <div class="tpl-items">
        <img
          class="tpl-item"
          src="https://gw.alicdn.com/imgextra/i1/O1CN01jI3cPn1xMzZTspfgI_!!6000000006430-0-tps-576-768.jpg"
        />
        <img
          class="tpl-item"
          src="https://gw.alicdn.com/imgextra/i2/O1CN01dn9IDe1Zx82kZlBHp_!!6000000003260-0-tps-576-768.jpg"
        />
        <img
          class="tpl-item"
          src="https://gw.alicdn.com/imgextra/i1/O1CN01jOMRgX1npFqxpECKa_!!6000000005138-0-tps-576-768.jpg"
        />
      </div>
      <div class="tpl-title">模板—公主日记</div>
      <div class="tpl-items">
        <img
          class="tpl-item"
          src="https://gw.alicdn.com/imgextra/i1/O1CN01s3bxt51U7lXvEv0ad_!!6000000002471-0-tps-768-1024.jpg"
        />
        <img
          class="tpl-item"
          src="https://gw.alicdn.com/imgextra/i1/O1CN01n6LLQY1fnrjBIXlgp_!!6000000004052-0-tps-768-1024.jpg"
        />
        <img
          class="tpl-item"
          src="https://gw.alicdn.com/imgextra/i2/O1CN01H3nzwT1svH8nfxTBa_!!6000000005828-0-tps-768-1024.jpg"
        />
      </div>
      <div class="tpl-title">模板—Z世代｜末日废土</div>
      <div class="tpl-items">
        <img
          class="tpl-item"
          src="https://gw.alicdn.com/imgextra/i1/O1CN015BUXgY27ezI5xg0iQ_!!6000000007823-0-tps-576-768.jpg"
        />
        <img
          class="tpl-item"
          src="https://gw.alicdn.com/imgextra/i1/O1CN013wFVUY1lnIwB3g0V7_!!6000000004863-0-tps-576-768.jpg"
        />
        <img
          class="tpl-item"
          src="https://gw.alicdn.com/imgextra/i4/O1CN011mE7G22AJrsMBzpKK_!!6000000008183-0-tps-576-768.jpg"
        />
      </div>
      <div class="tpl-title">模板—瑜伽</div>
      <div class="tpl-items">
        <img
          class="tpl-item"
          src="https://gw.alicdn.com/imgextra/i3/O1CN010tb4au1MV6oAn8Ldo_!!6000000001439-0-tps-576-768.jpg"
        />
        <img
          class="tpl-item"
          src="https://gw.alicdn.com/imgextra/i4/O1CN01qxuk2e1L65YUI6xL7_!!6000000001249-0-tps-576-768.jpg"
        />
        <img
          class="tpl-item"
          src="https://gw.alicdn.com/imgextra/i2/O1CN017B6G8Q20ZJ4JiXouK_!!6000000006863-0-tps-576-768.jpg"
        />
      </div>
      <div class="tpl-title">模板—千年｜新青年</div>
      <div class="tpl-items">
        <img
          class="tpl-item"
          src="https://gw.alicdn.com/imgextra/i1/O1CN01mqQDzw1x7sV9HbOYU_!!6000000006397-0-tps-576-768.jpg"
        />
        <img
          class="tpl-item"
          src="https://gw.alicdn.com/imgextra/i3/O1CN01m1ioP123isBhyQlyT_!!6000000007290-0-tps-576-768.jpg"
        />
        <img
          class="tpl-item"
          src="https://gw.alicdn.com/imgextra/i1/O1CN01R2fXin1GfHuoMTYP1_!!6000000000649-0-tps-576-768.jpg"
        />
      </div> -->




    <div class="footer">
      <!-- <span class="item" style="margin-left: 16px">沪ICP备2023013144号</span> -->
      <a target="_blank" class="item" style="margin-left: 16px">Copyright © 2019 深圳燚麟信息技术有限公司 粤ICP备2021034056号-1</a>
      <!-- <a href="" target="_blank" class="item" style="margin-left: 16px">妙鸭相机隐私政策</a> -->
    </div>
  </div>
</template>
<style scoped>
   body {
        font-family: BlinkMacSystemFont, 'Segoe UI', 'PingFang SC',
          'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica,
          Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
          'Segoe UI Symbol';
        background-color: #fff;
      }
      @media screen and (min-width: 778px) {
        #root {
          width: 100%;
        }
        #root img {
          vertical-align: middle;
          border-style: none;
        }
        #root .main {
          margin: 200px auto 0px auto;
          width: 778px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #fff;
          overflow: hidden;
        }
        .main .main-a {
          display: flex;
          flex-direction: column;
        }
        .main .logo {
          width: 196px;
          height: 44px;
          background-color: #fff;
        }
        .main .desc-title {
          margin-top: 43px;
          width: 84px;
          height: 38px;
        }
        .main .desc-content {
          margin-top: 17px;
          font-size: 11px;
        }
        .main .main-b {
          margin-left: 62px;
        }
        .main .big-logo {
          width: 277px;
          height: 224px;
          background-color: #fff;
        }
        #root .download {
          margin: 18px auto 0px auto;
          width: 778px;
          display: flex;
          background: #fff;
          overflow: hidden;
        }
        .download .dl-item {
          margin-right: 16px;
          display: flex;
          flex-direction: column;
          width: 125px;
        }
        .download .dl-button {
          height: 41px;
          width: 125px;
          background-color: #222222;
          border-radius: 11px;
          display: flex;
        }
        .download .dl-icon {
          display: block;
          margin: 12px;
          width: 17px;
          height: 17px;
        }
        .download .dl-text {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        .download .dl-link {
          position: absolute;
          display: block;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          z-index: 99;
        }
        .download .dl-store {
          height: 17px;
          width: 78px;
          font-family: PingFangSC;
          font-weight: 500;
          font-size: 12px;
          color: #ffffff;
          text-align: center;
        }
        .download .dl-dl {
          padding-left: 5px;
          height: 13px;
          width: 78px;
          opacity: 0.5;
          font-family: PingFangSC;
          font-weight: 500;
          font-size: 9px;
          color: #ffffff;
        }
        .download .dl-qrcode {
          margin: 12px auto 0 auto;
          height: 87.8px;
          width: 83px;
          background-image: url(https://gw.alicdn.com/imgextra/i1/O1CN01j9JNK21cEC1z90GOA_!!6000000003568-2-tps-249-264.png);
          background-size: 100% 100%;
        }
        .download .qr-img {
          margin: 12px 0 0 6px;
          display: block;
          border: none;
          width: 70px;
          height: 70px;
        }
        .tpl-banner {
          margin: 60px 0 12px 0;
          display: flex;
          align-content: center;
          justify-content: center;
        }
        .tpl-banner .tpl-img {
          display: block;
          width: 125px;
          height: 40px;
        }
        .tpl-title {
          margin: 0 auto;
          height: 25px;
          width: 550px;
          font-family: PingFangSC;
          font-weight: 600;
          font-size: 18px;
          color: #222222;
        }
        .tpl-items {
          width: 550px;
          margin: 6px auto 30px auto;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
        .tpl-items .tpl-item {
          display: block;
          border: none;
          height: 217px;
          width: 163px;
          border-radius: 14px;
        }
        .footer {
          margin-top: 50px;
          margin-bottom: 50px;
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
        .footer .item {
          margin: 0 34px;
          font-size: 10px;
          color: #8d8d8d;
        }
      }
      @media screen and (max-width: 778px) {
        #root {
          width: 100%;
        }
        #root img {
          vertical-align: middle;
          border-style: none;
        }
        #root .main {
          margin: 56px auto 0px auto;
          width: 375px;
          display: flex;
          flex-direction: column-reverse;
          justify-content: center;
          align-items: center;
          background: #fff;
          overflow: hidden;
        }
        .main .main-a {
          display: flex;
          flex-direction: column;
        }
        .main-a .logo {
          margin-top: 18px;
          width: 158px;
          height: 35px;
        }
        .main-a .desc-title {
          display: none;
          margin-top: 43px;
          width: 84px;
          height: 38px;
        }
        .main-a .desc-content {
          margin-top: 18px;
          font-size: 11px;
          line-height: 21px;
        }
        .main .main-b {
          margin-left: 62px;
        }
        .main-b .big-logo {
          width: 299px;
          height: 246px;
          background-color: #fff;
        }
        #root .download {
          margin: 18px auto 0px auto;
          width: 375px;
          display: flex;
          background: #fff;
          overflow: hidden;
          justify-content: center;
        }
        .download .dl-item {
          margin-right: 16px;
          display: flex;
          flex-direction: column;
          width: 125px;
        }
        .download .dl-button {
          height: 41px;
          width: 125px;
          background-color: #222222;
          border-radius: 11px;
          display: flex;
        }
        .download .dl-icon {
          display: block;
          margin: 12px;
          width: 17px;
          height: 17px;
        }
        .download .dl-text {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        .download .dl-link {
          position: absolute;
          display: block;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          z-index: 99;
        }
        .download .dl-store {
          height: 17px;
          width: 78px;
          font-family: PingFangSC;
          font-weight: 500;
          font-size: 12px;
          color: #ffffff;
          text-align: center;
        }
        .download .dl-dl {
          padding-left: 5px;
          height: 13px;
          width: 78px;
          opacity: 0.5;
          font-family: PingFangSC;
          font-weight: 500;
          font-size: 9px;
          color: #ffffff;
        }
        .download .dl-qrcode {
          display: none;
          margin: 12px auto 0 auto;
          height: 87.8px;
          width: 83px;
          background-image: url(https://gw.alicdn.com/imgextra/i1/O1CN01j9JNK21cEC1z90GOA_!!6000000003568-2-tps-249-264.png);
          background-size: 100% 100%;
        }
        .download .qr-img {
          margin: 12px 0 0 6px;
          display: block;
          border: none;
          width: 70px;
          height: 70px;
        }
        .tpl-banner {
          margin: 60px 0 12px 0;
          display: flex;
          align-content: center;
          justify-content: center;
        }
        .tpl-banner .tpl-img {
          display: block;
          width: 125px;
          height: 40px;
        }
        .tpl-title {
          margin: 0 auto;
          height: 25px;
          width: 316px;
          font-family: PingFangSC;
          font-weight: 600;
          font-size: 18px;
          color: #222222;
        }
        .tpl-items {
          width: 316px;
          margin: 6px auto 18px auto;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
        .tpl-items .tpl-item {
          display: block;
          border: none;
          height: 125px;
          width: 94px;
          border-radius: 9px;
        }
        .footer {
          margin-top: 50px;
          margin-bottom: 50px;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        .footer .item {
          margin: 0 34px;
          font-size: 10px;
          line-height: 21px;
          color: #8d8d8d;
        }
      }
</style>
<script>
export default {
  name: 'YourComponentName',
  // 组件的其他属性和逻辑可以在这里添加
  created() {
    document.title = '碧麒助理';
  }
};
</script>
